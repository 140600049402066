import React, { useState, useEffect, useRef } from "react";
import Control from "../../common/Control";
import PasswordControl from "../../common/PasswordControl";
import Axios from "axios";
import { api_url } from "../../../config";
import Loader from "../../common/Loader";
import GroupedDigitControl from "../../common/GroupedDigitControl";
import FormGenerator from "../../common/FormGenerator/FormGenerator";
import jwt_decode from "jwt-decode";
import Modal from "../../common/Modal";
import ModalBtn from "../../common/ModalBtn";
import ForgotPassword from "../Login/ForgotPassword";
import { Captcha, captchaSettings } from "reactjs-captcha";

captchaSettings.set({
  captchaEndpoint: `${api_url}simple-captcha-endpoint`,
});

const FormAccount = ({
  isAccountFinished,
  setIsAccountFinished,
  formData,
  setUserData,
  setUserCreated,
  origin,
}) => {
  const captchaRef = useRef();
  const [password, setPassword] = useState("");
  const [isLoginIn, setIsLoginIn] = useState(false);
  const [passwordConnect, setPasswordConnect] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errors, setErrors] = useState({});
  const [canSave, setCanSave] = useState(false);
  const [passwordsEqual, setPasswordsEqual] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accountExists, setAccountExists] = useState(false);
  const [NIR, setNIR] = useState("");
  const [nirValid, setNirValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  useEffect(() => {
    setErrors({});
    var errs = {};
    if (password != passwordConfirm || password.length == 0) {
      errs.passwordConfirm = "Doit-être identique au premier";
    }

    if (Object.keys(errs).length === 0 && errs.constructor === Object) {
      setPasswordsEqual(true);
    } else {
      setPasswordsEqual(false);
    }
    setErrors(errs);
  }, [password, passwordConfirm]);

  function checkNIR() {
    Axios.post(api_url + "recipients/exist", {
      nir: NIR,
    })
      .then((res) => {
        setNirValid(true);
        setAccountExists(res.data.exist);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  }

  function authenticate() {
    let postData = {
      login: NIR,
      password: passwordConnect,
      // captcha: null,
    };
    setIsLoginIn(true);
    // if (captchaRef.current) {
    //   let userEnteredCaptchaCode =
    //     captchaRef.current.getUserEnteredCaptchaCode();
    //   // Récupération de l'id du captcha généré par la librairie
    //   let captchaId = captchaRef.current.getCaptchaId();

    //   //Objet envoyé au backend pour valider le captcha contenant l'id et le code du captcha
    //   postData.captcha = { code: userEnteredCaptchaCode, id: captchaId };
    // }
    Axios.post(api_url + "auth/login/app", postData)
      .then((res) => {
        setUserData(jwt_decode(res.data));
        setIsAccountFinished(true);
      })
      .catch((err) => {
        if (err.response && err.response.data)
          setErrors({
            login:
              "Une erreur s'est produite, veuillez vérifier vos informations.",
            ...err.response.data,
          });
        // if (captchaRef.current) {
        //   try {
        //     captchaRef.current.reloadImage();
        //   } catch (err) {}
        // }
      })
      .then(() => {
        setIsLoginIn(false);
      });
  }

  return (
    <>
      <Modal
        id="forgotPasswordModal"
        title="Demande de réinitialisation du mot de passe"
      >
        <ForgotPassword nir={NIR} inModal={true} />
      </Modal>

      {isLoading ? (
        <Loader />
      ) : !nirValid ? (
        <div className="col-12 col-lg-8 col-md-8 m-auto px-0">
          <div className="row m-0">
            <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center">
              <img
                style={{ maxHeight: 196 }}
                className="img-fluid mb-3"
                src="/assets/carte-vitale.png"
                alt="spécimen de carte vitale"
              />
            </div>
            <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center">
              <p className="text-primary">
                Votre numéro de sécurité sociale figure sur la face recto de
                votre carte vitale (cf image).
              </p>
            </div>
          </div>
          <div className="col-12 mb-3">
            <label htmlFor="nir-login-inp">
              Entrez votre numéro de sécurité sociale (NIR)
            </label>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              checkNIR();
            }}
          >
            <GroupedDigitControl
              name={"NIR"}
              value={NIR}
              separation={[1, 2, 2, 2, 3, 3, 2]}
              separator={"   "}
              change={(e) => {
                if (e.target.value == "269054958815780") {
                  alert(
                    "Vous ne devez pas rentrer le numéro affiché sur l'image mais celui de votre propre carte vitale."
                  );
                } else {
                  setNIR(e.target.value);
                }
              }}
              autoFocus={true}
            />
            <span className="text-danger">{errors["nir"]}</span>
            {NIR != "269054958815780" && (
              <button
                type="button"
                className="btn btn-success w-100 mt-5"
                onClick={checkNIR}
              >
                Continuer
              </button>
            )}
          </form>
        </div>
      ) : !accountExists ? (
        <>
          {!passwordValid ? (
            <div className="col-12 col-lg-8 col-md-8 m-auto px-0">
              <Control
                className="mt-5"
                label="Choisissez un mot de passe"
                type="password"
                name="password"
                value={password}
                change={(e) => setPassword(e.target.value)}
                error={errors}
                isValid={!errors.password && password != "" && canSave}
              />
              <PasswordControl
                style={{ marginTop: -32 }}
                password={password}
                minLength={9}
                minUppercase={1}
                minLowercase={3}
                minNumber={1}
                setCanSave={setCanSave}
              />
              <Control
                label="Confirmez votre mot de passe"
                type="password"
                name="passwordConfirm"
                value={passwordConfirm}
                change={(e) => setPasswordConfirm(e.target.value)}
                error={errors}
                isValid={!errors.passwordConfirm && passwordConfirm != ""}
              />

              {canSave && passwordsEqual && (
                <button
                  type="button"
                  className={
                    "btn mt-3 w-100 btn-success animated zoomIn faster"
                  }
                  onClick={() => setPasswordValid(true)}
                >
                  Continuer
                </button>
              )}
            </div>
          ) : (
            <FormGenerator
              formDataUrl={api_url + "recipients/config"}
              formValidationUrl={api_url + "recipients/validation"}
              formSubmitUrl={api_url + "recipients"}
              isDspFinished={isAccountFinished}
              setIsDspFinished={setIsAccountFinished}
              setFormData={setUserData}
              withContinue={false}
              formatSaveData={true}
              additionalDataToSave={{
                password: password,
                nir: NIR,
                origin: origin,
              }}
              closingPhrase="Veuillez patienter pendant l'enregistrement de vos informations."
              successPhrase="Enregistrement réussi"
              origin={origin}
              checkEmail={true}
            />
          )}
        </>
      ) : (
        <div className="col-12 col-lg-8 col-md-8 m-auto px-0">
          <div className="alert alert-info">
            Nous avons détecté que vous possédez déjà un compte, veuillez
            rentrer votre mot de passe pour continuer.
          </div>
          <div
            className="mx-auto"
            style={{
              maxWidth: 450,
            }}
          >
            <Control
              className=""
              label="Renseignez votre mot de passe"
              type="password"
              name="password"
              value={passwordConnect}
              change={(e) => setPasswordConnect(e.target.value)}
              error={errors}
            />

            {/* {(errors.login || errors.password || errors.captcha) && (
              <>
                <Captcha captchaStyleName="captchaFR" ref={captchaRef} />
                <label className="w-100 mt-2">
                  <input
                    className="form-control d-block "
                    id="captchaFormulaireExtInput"
                    type="text"
                    placeholder="Saisissez le code ci-dessus"
                  />
                </label>
                {errors.captcha && (
                  <span className="text-danger">{errors.captcha}</span>
                )}
              </>
            )} */}

            {errors.other && (
              <div className="text-danger animated flash mb-2">
                {errors.other}
              </div>
            )}
            <button
              disabled={isLoginIn}
              className="btn btn-success w-100"
              onClick={authenticate}
            >
              {isLoginIn ? <Loader color="white" /> : "Me connecter"}
            </button>
            <ModalBtn
              id="forgotPasswordModal"
              label="Mot de passe oublié ?"
              btnClass="btn btn-link w-100 mt-2"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FormAccount;
